<template>
  <div class="alerts">
    <div class="alert-list p-4">
      <widget headerText="Liste des alertes">
        <b-table
          striped
          hover
          :items="alerts"
          :fields="fields"
          :tbody-tr-class="rowClass"
          ><template #cell(url)="data">
            <a
              class="action-cell"
              v-if="data.value.includes('@') || data.value == null"
              :href="`mailto:${data.value}`"
              ><ion-icon
                name="mail"
                style="font-size: 1.6rem"
                v-b-tooltip.hover
                title="Envoyer un mail"
            /></a>
            <a
              class="action-cell"
              v-else
              v-on:click="() => handleActionClick(data.value)"
              ><ion-icon
                name="search"
                style="font-size: 1.6rem"
                v-b-tooltip.hover
                title="Aller à la page"
            /></a>
          </template>
          <template #cell(status)="row">
            <a
              class="action-cell"
              v-if="row.item.status == 2"
              v-on:click="() => handleStatusClick(row.item)"
              ><ion-icon
                name="mail-unread"
                style="font-size: 1.6rem"
                v-b-tooltip.hover
                title="Non lue"
            /></a>
            <a
              class="action-cell"
              v-else-if="row.item.status == 1"
              v-on:click="() => handleStatusClick(row.item)"
              ><ion-icon
                name="mail"
                style="font-size: 1.6rem"
                v-b-tooltip.hover
                title="Lue"
            /></a>
            <a
              class="action-cell"
              v-else
              v-on:click="() => handleStatusClick(row.item)"
              ><ion-icon
                name="mail-open"
                style="font-size: 1.6rem"
                v-b-tooltip.hover
                title="Archivée"
            /></a> </template
        ></b-table>
        <div class="pagination d-flex justify-content-end">
          <nav aria-label="...">
            <ul class="pagination">
              <li class="page-item" v-if="page > 0">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page -= 1;
                    }
                  "
                  >Précédent</span
                >
              </li>
              <li class="page-item" v-if="page > 0">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page -= 1;
                    }
                  "
                  >{{ page }}</span
                >
              </li>
              <li class="page-item active">
                <span class="page-link" href="#"
                  >{{ page + 1 }} <span class="sr-only">(current)</span></span
                >
              </li>
              <li class="page-item" v-if="hasMorePages">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page += 1;
                    }
                  "
                  >{{ page + 2 }}</span
                >
              </li>
              <li class="page-item" v-if="hasMorePages">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page += 1;
                    }
                  "
                  >Suivant</span
                >
              </li>
            </ul>
          </nav>
        </div>
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget.vue";
import axios from "axios";
export default {
  name: "alerts",
  components: { Widget },
  data() {
    return {
      alerts: [],
      page: 0,
      pages: 1,
      per_page: 10,
      fields: [
        {
          key: "type",
          label: "Type d'alerte",
          formatter: this.typeFormatter,
        },
        {
          key: "recipient",
          label: "Accueilli",
          formatter: (recipient) =>
            recipient.surname + " " + recipient.name.toUpperCase(),
        },
        {
          key: "date",
          label: "Date",
          formatter: (date) =>
            date ? this.$moment(date).format("DD/MM/YYYY") : "",
        },
        {
          key: "url",
          label: "Action",
        },
        {
          key: "status",
          label: "Status",
        },
      ],
    };
  },
  watch: {
    page() {
      this.refreshAlerts();
    },
  },
  computed: {
    hasMorePages() {
      return this.page + 1 < this.pages;
    },
  },
  mounted() {
    this.refreshAlerts();
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status > 0) return "table-warning";
    },
    refreshAlerts() {
      axios
        .get(`/api/web/alerts`, {
          params: {
            page: this.page,
            per_page: this.per_page,
          },
        })
        .then((res) => {
          this.alerts = res.data.data;
          this.pages = res.data.pagination.last_page + 1;
        });
    },
    typeFormatter(type) {
      return {
        dom_alert: "Fin de domiciliation",
        housing_alert: "Fin d'hébergement",
        summary_alert: "Bilan requis",
        prison_alert: "Fin d'incarcération",
      }[type];
    },
    handleActionClick(url) {
      this.$router.push(url);
    },
    handleStatusClick(alert) {
      axios
        .post(`/api/web/alert/${alert._id}`, {
          status: alert.status > 0 ? 0 : 1,
        })
        .then(() => {
          this.refreshAlerts();
          this.$store.dispatch("setRefreshFlag", "alerts");
        });
    },
  },
};
</script>

<style>
.action-cell {
  cursor: pointer;
}
.page-link {
  cursor: pointer;
}
</style>