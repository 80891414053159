<template>
  <div class="housings">
    <div class="housing-list p-4">
      <widget headerText="Liste des Hébergements">
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <object-auto-complete
              v-model="filter.user_id"
              :options="users"
              field_of_value="_id"
              label="Filtrer sur l'utilisateur"
            >
            </object-auto-complete>
          </div>

          <button class="btn btn-primary" v-on:click="refreshHousingList()">
            Filtrer
          </button>
        </div>

        <rotate-square2 v-if="isLoading"></rotate-square2>
        <b-table
          v-else
          striped
          hover
          :items="housings"
          :fields="fields"
          @row-clicked="handleRowClick"
          :tbody-tr-class="housingClass"
          :sort-by="filter.sortBy"
          :sort-desc="filter.sortDesc"
          @sort-changed="sortChanged"
        ></b-table>
        <div class="pagination d-flex justify-content-end">
          <nav aria-label="...">
            <ul class="pagination">
              <li class="page-item" v-if="page > 0">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page -= 1;
                    }
                  "
                  >Précédent</span
                >
              </li>
              <li class="page-item" v-if="page > 0">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page -= 1;
                    }
                  "
                  >{{ page }}</span
                >
              </li>
              <li class="page-item active">
                <span class="page-link" href="#"
                  >{{ page + 1 }} <span class="sr-only">(current)</span></span
                >
              </li>
              <li class="page-item" v-if="hasMorePages">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page += 1;
                    }
                  "
                  >{{ page + 2 }}</span
                >
              </li>
              <li class="page-item" v-if="hasMorePages">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page += 1;
                    }
                  "
                  >Suivant</span
                >
              </li>
            </ul>
          </nav>
        </div>
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget";
import axios from "axios";
import { mapState } from "vuex";
import ObjectAutoComplete from "../../components/widgets/ObjectAutoComplete.vue";
import { RotateSquare2 } from "vue-loading-spinner";
export default {
  name: "housings",
  components: { Widget, ObjectAutoComplete, RotateSquare2 },
  data() {
    return {
      housings: [],
      users: [],
      page: 0,
      pages: 1,
      per_page: 20,
      filter: {
        sortBy: "start_date",
        sortDesc: true,
        user_id: null,
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapState(["user", "antennas"]),
    hasMorePages() {
      return this.page + 1 < this.pages;
    },
    fields() {
      return [
        {
          key: "recipient.surname",
          label: "Prénom",
          sortable: true,
        },
        {
          key: "recipient.name",
          label: "Nom",
          sortable: true,
        },
        {
          key: "hotel.name",
          label: "Hôtel",
          sortable: true,
        },
        {
          key: "hotel.address.postal_code",
          label: "CP",
        },
        {
          key: "room",
          label: "Chambre",
          formatter: (val) => (val ? val.number : ""),
        },
        {
          key: "user",
          label: "Référent MRS",
          formatter: (val) =>
            val
              ? val.surname + " " + (val.name ? val.name.toUpperCase() : "")
              : "",
          sortable: true,
        },
        {
          key: "start_date",
          label: "Du",
          sortable: true,
          formatter: (val) =>
            val ? this.$moment(val).format("DD/MM/YYYY") : "",
        },
        {
          key: "end_date",
          label: "Au",
          sortable: true,
          formatter: (val) =>
            val ? this.$moment(val).format("DD/MM/YYYY") : "",
        },
        {
          key: "free_end_date",
          label: "Libérée le",
          sortable: true,
          formatter: (val) =>
            val ? this.$moment(val).format("DD/MM/YYYY") : "",
        },
        {
          key: "nights",
          label: "Nuitées",
        },
        {
          key: "price",
          label: "Quote part",
          formatter: (val) => (val ? val + " €" : ""),
        },
        {
          key: "total_price",
          label: "Total",
          formatter: (val) => val + " €",
        },
        {
          key: "total_payment",
          label: "Payé",
          formatter: (val) => val + " €",
        },
        {
          key: "solde",
          label: "Solde",
          formatter: (val) => val + " €",
          tdClass: (value) => (value < 0 ? "table-warning" : ""),
        },
      ];
    },
  },
  mounted() {
    this.refreshHousingList();
    this.refreshLists();
  },
  watch: {
    page() {
      this.refreshHousingList();
    },
    searchInput() {
      this.refreshHousingList();
    },
  },
  methods: {
    sortChanged(e) {
      this.filter.sortBy = e.sortBy;
      this.filter.sortDesc = e.sortDesc;
      this.refreshHousingList();
    },
    housingClass(housing, type) {
      if (type == "row") {
        if (
          this.$moment(housing.end_date) - this.$moment() < 0 &&
          (housing.free_end_date == "" || housing.free_end_date == undefined)
        ) {
          return "table-danger";
        } else if (
          this.$moment(housing.end_date) - this.$moment() > 0 &&
          this.$moment(housing.start_date) - this.$moment() <= 0
        ) {
          return "table-success";
        }
      } else {
        return;
      }
    },
    refreshLists() {
      if (
        this.user.habilitation == "volunteer" ||
        this.user.habilitation == "antenna_admin"
      ) {
        axios
          .get(`/api/web/users/antenna/${this.user.antenna_id}`)
          .then((res) => {
            this.users = res.data.data;
          });
      } else {
        axios.get(`/api/web/users`).then((res) => {
          this.users = res.data.data;
        });
      }
    },
    refreshHousingList() {
      this.isLoading = true;
      axios
        .get("/api/web/housings", {
          params: {
            filter: this.filter,
            page: this.page,
            per_page: this.per_page,
          },
        })
        .then((res) => {
          this.pages = res.data.pagination.last_page + 1;
          this.housings = res.data.data.map((h) => {
            h.nights = this.$moment(h.end_date).diff(
              this.$moment(h.start_date),
              "days"
            );
            h.total_price = h.price * h.nights;
            h.total_payment = h.payments.reduce((a, b) => a + b.amount, 0);
            h.solde = h.total_payment - h.total_price;
            return h;
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    handleRowClick(item) {
      this.$router.push(`/recipient/${item.recipient_id}#housing`);
    },
  },
};
</script>