<template>
  <vuestic-modal
    class="domiciliation-modal"
    ref="domiciliationModal"
    :okText="'Enregistrer'"
    :okFunction="okAction"
    @cancel="resetForm"
    large
    ><div slot="title">
      <span class="center">{{ getTitle() }}</span>
    </div>
    <md-checkbox v-model="isMrs" :value="true" class="md-primary mt-2"
      >Domiciliation au MRS</md-checkbox
    >
    <form v-if="isMrs" novalidate @submit.prevent="addDomiciliation">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-big-size-100">
          <object-auto-complete
            :inputClass="getValidationClass('user_id')"
            v-model="currentDomiciliation.user_id"
            :options="users"
            field_of_value="_id"
            label="Accueillant domiciliateur"
          >
            <span
              class="md-error"
              v-if="!$v.currentDomiciliation.user_id.required"
              >Un référent est requis</span
            >
          </object-auto-complete>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field :class="getValidationClass('paper')">
            <label for="paper">Pièce justificative</label>
            <md-select
              name="paper"
              id="paper"
              v-model="currentDomiciliation.paper"
              ><md-option
                v-for="(p, i) in [
                  'CNI',
                  'Titre de séjour',
                  'Récépissé CNI',
                  'Récépissé Titre de séjour',
                  'Fiche de liaison SPIP',
                ]"
                :key="i"
                :value="p"
              >
                {{ p }}
              </md-option>
            </md-select>
            <span
              class="md-error"
              v-if="!$v.currentDomiciliation.paper.required"
              >La pièce justificative est requise</span
            >
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="dom-origin">Origine de la demande </label>
            <md-input
              name="dom-origin"
              id="dom-origin"
              v-model="currentDomiciliation.origin"
            />
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item d-flex align-items-center">
          <md-field
            :class="getValidationClass('start_date')"
            class="no-underline"
          >
            <div class="d-flex align-items-center justify-content-center">
              Date de début :
              <vuestic-date-picker
                v-model="currentDomiciliation.start_date"
                :config="dateConfig"
                @input="changeEndDate"
              />
            </div>
            <span
              class="md-error"
              v-if="!$v.currentDomiciliation.start_date.required"
              >La date de début est requise</span
            >
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="duration">Durée en mois</label>
            <md-input
              name="duration"
              id="duration"
              v-model="duration"
              @input="changeEndDate"
            />
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field
            class="no-underline"
            :class="getValidationClass('end_date')"
          >
            <div class="d-flex align-items-center justify-content-center">
              Fin :
              <vuestic-date-picker
                v-model="currentDomiciliation.end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field
            class="no-underline"
            :class="getValidationClass('last_meeting')"
          >
            <div class="d-flex align-items-center justify-content-center">
              Date d'entretien:
              <vuestic-date-picker
                v-model="currentDomiciliation.last_meeting"
                :config="dateConfig"
              />
            </div>
            <span
              class="md-error"
              v-if="!$v.currentDomiciliation.last_meeting.required"
              >La date de l'entretien est requise</span
            >
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          Commentaire (adresse de redirection, etc...) :
          <b-form-textarea
            class="pt-2"
            id="adress"
            v-model="currentDomiciliation.remarque"
            rows="3"
            max-rows="3"
          />
        </div>
      </div>
    </form>
    <form v-else novalidate @submit.prevent="addDomiciliation">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="name_dom">Nom du domiciliant</label>
            <md-input
              name="name_dom"
              id="name_dom"
              v-model="currentDomiciliation.name_dom"
            />
          </md-field>
        </div>

        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de début :
              <vuestic-date-picker
                v-model="currentDomiciliation.start_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
        <div class="md-layout-item d-flex align-items-center">
          <md-field class="no-underline">
            <div class="d-flex align-items-center justify-content-center">
              Date de fin :
              <vuestic-date-picker
                v-model="currentDomiciliation.end_date"
                :config="dateConfig"
              />
            </div>
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          Adresse :
          <b-form-textarea
            class="pt-2"
            label="Adresse"
            id="adress"
            v-model="currentDomiciliation.address"
            rows="3"
            max-rows="3"
          />
        </div>
      </div>
    </form>
  </vuestic-modal>
</template>

<script>
import VuesticModal from "../widgets/VuesticModal.vue";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import ObjectAutoComplete from "../../components/widgets/ObjectAutoComplete.vue";
import axios from "axios";

import { French } from "flatpickr/dist/l10n/fr.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "domiciliation-modal",
  components: { VuesticModal, VuesticDatePicker, ObjectAutoComplete },
  mixins: [validationMixin],
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
    antenna_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      duration: "12",
      isMrs: true,
      users: [],
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      currentDomiciliation: {},
      originDomiciliation: {},
      domiciliation: {},
    };
  },
  validations() {
    if (this.isMrs) {
      const isDate = (val) => this.$moment(val).isValid();
      return {
        currentDomiciliation: {
          start_date: {
            required,
            isDate,
          },
          last_meeting: {
            required,
            isDate,
          },
          paper: {
            required,
          },
          user_id: {
            required,
          },
        },
      };
    } else {
      return {
        currentDomiciliation: {},
      };
    }
  },
  mounted() {
    this.refreshUsers();
  },
  watch: {
    antenna_id() {
      this.refreshUsers();
    },
    currentDomiciliation: {
      deep: true,
      handler() {
        this.$v.$reset();
      },
    },
  },
  methods: {
    changeEndDate() {
      this.currentDomiciliation.end_date = this.$moment(
        this.currentDomiciliation.start_date
      )
        .add(this.duration.toString(), "month")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
    },
    getTitle() {
      return this.domiciliation._id
        ? "Modification d'une domiciliation"
        : "Nouvelle domiciliation";
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentDomiciliation[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentDomiciliation[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    refreshUsers() {
      let url = this.antenna_id
        ? `/api/web/users/antenna/${this.antenna_id}`
        : "/api/web/users";
      axios.get(url).then((res) => {
        this.users = res.data.data;
      });
    },
    open(domiciliation) {
      this.$refs.domiciliationModal.open();
      this.domiciliation = domiciliation;
      this.originDomiciliation = this._.cloneDeep(this.domiciliation);
      this.currentDomiciliation = this._.cloneDeep(this.domiciliation);
      this.isMrs =
        this.originDomiciliation._id === undefined ||
        this.originDomiciliation.user_id !== undefined;
      if (this.currentDomiciliation.start_date == undefined) {
        this.currentDomiciliation.start_date =
          this.$moment().format("YYYY-MM-DD");
        this.changeEndDate();
      }
      if (this.currentDomiciliation.last_meeting == undefined) {
        this.currentDomiciliation.last_meeting =
          this.$moment().format("YYYY-MM-DD");
      }
      this.refreshUsers();
    },
    formatDomiciliation(domiciliation) {
      if (this.isMrs) {
        domiciliation.antenna_id = this.antenna_id;
      }
      return domiciliation;
    },
    resetForm() {
      this.originDomiciliation = {};
      this.currentDomiciliation = {};
      this.duration = "12";
    },
    okAction() {
      this.$v.$touch();

      if (!this.$v.$error) {
        if (this.domiciliation._id) {
          this.saveDomiciliation();
        } else {
          this.createDomiciliation();
        }
        this.$v.$reset();
        this.resetForm();
        return true;
      } else {
        this.$toasted.show("Des champs sont manquants").goAway(1500);
        return false;
      }
    },
    createDomiciliation() {
      let body = {
        domiciliation: this.formatDomiciliation(this.currentDomiciliation),
      };
      axios
        .put(`/api/web/recipient/${this.recipient_id}/domiciliations`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Domiciliation créée").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "domiciliation");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
      return true;
    },
    saveDomiciliation() {
      let body = {
        current: this.formatDomiciliation(this.currentDomiciliation),
        origin: this.domiciliation,
        fail_on_concurrent: false,
      };
      axios
        .post(`/api/web/domiciliation/${this.domiciliation._id}`, body)
        .then((res) => {
          if (res.data.success) {
            this.$toasted.show("Domiciliation mis à jour").goAway(1500);
            this.$store.dispatch("setRefreshFlag", "domiciliation");
          } else {
            this.$toasted.error("Erreur inconnue").goAway(1500);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.no-underline {
  &.md-field:before {
    visibility: hidden !important;
  }
  &.md-field:after {
    visibility: hidden !important;
  }
}
.domiciliation-modal {
  .md-list-item {
    z-index: 10002;
  }
}
</style>