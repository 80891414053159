<template>
  <div class="recipient-housing">
    <div class="list-housing pt-2 pl-4 pr-4">
      <widget :headerText="getTitle">
        <div class="md-layout-item">
          <md-field>
            <label for="place">Type d'hébergements</label>
            <md-select name="place" id="place" v-model="housingSelected">
              <md-option
                v-for="(o, i) in [
                  'Hébergements MRS',
                  'Hébergements d\'urgence',
                  'Hébergements hors MRS',
                ]"
                :key="i"
                :value="o"
              >
                {{ o }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <b-table
          v-if="housings.length > 0"
          striped
          hover
          :items="housings"
          :fields="fields"
          :tbody-tr-class="housingClass"
        >
          <template #cell(actions)="row">
            <ion-icon
              v-if="!isTotal(row.item)"
              name="trash"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Supprimer"
              @click="askDelete(row.item)"
            ></ion-icon
            ><ion-icon
              v-if="!isTotal(row.item)"
              class="pl-2"
              name="pencil"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Modifier"
              @click="editHousing(row.item)"
            ></ion-icon> </template
        ></b-table>
        <button type="button" class="btn btn-primary" @click="addHousing">
          Ajouter un hébergement
        </button>
      </widget>
    </div>
    <housing-help
      :recipient_id="recipient_id"
      @openHousingHelpModal="openHousingHelpModal"
      class="pl-4 pr-4"
    />
    <comments
      :recipient_id="recipient_id"
      field="housing"
      @openCommentModal="openCommentModal"
    />
  </div>
</template>

<script>
import Comments from "../comment/Comments.vue";
import HousingHelp from "../helps/HousingHelp.vue";
import Widget from "../widgets/Widget.vue";
import axios from "axios";
import { mapState } from "vuex";
import { telFormatter } from "../../services/utils";
export default {
  name: "recipient-housing",
  components: { Widget, Comments, HousingHelp },
  props: {
    recipient_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      housingSelected: "Hébergements MRS",
      housings: [],
    };
  },
  mounted() {
    this.refreshHousing();
  },
  watch: {
    housingSelected() {
      this.refreshHousing();
    },
    refreshFlag() {
      if (this.refreshFlag == "housing") {
        this.refreshHousing();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  computed: {
    ...mapState(["refreshFlag", "user"]),
    fields() {
      if (this.housingSelected == "Hébergements MRS") {
        return [
          {
            key: "hotel.name",
            label: "Hotel",
          },
          {
            key: "hotel.address.postal_code",
            label: "CP",
          },
          {
            key: "room",
            label: "Chambre",
            formatter: (val) => (val ? val.number : ""),
          },
          {
            key: "type",
            label: "Type",
            formatter: (val) => (val ? val : ""),
          },
          {
            key: "user",
            label: "Référent",
            formatter: (val) => (val ? val.initials : ""),
          },
          {
            key: "start_date",
            label: "Du",
            formatter: (val) =>
              val ? this.$moment(val).format("DD/MM/YYYY") : "",
          },
          {
            key: "end_date",
            label: "Au",
            formatter: (val) =>
              val ? this.$moment(val).format("DD/MM/YYYY") : "",
          },
          {
            key: "without_contract",
            label: "Dont hors contrat",
            formatter: (val) =>
              val && val[0] > 0 ? val[0] + " nuits " + val[1] + " €" : "",
          },
          {
            key: "free_end_date",
            label: "Date de sortie",
            formatter: (val) =>
              val ? this.$moment(val).format("DD/MM/YYYY") : "",
          },
          {
            key: "nights",
            label: "Nuitées",
          },
          {
            key: "price",
            label: "Quote part",
            formatter: (val) => (val ? val + " €" : ""),
          },
          {
            key: "total_price",
            label: "Total",
            formatter: (val) => val + " €",
          },
          {
            key: "total_payment",
            label: "Payé",
            formatter: (val) => val + " €",
          },
          {
            key: "solde",
            label: "Solde",
            formatter: (val) => val + " €",
          },
          {
            key: "caution",
            label: "Caution",
          },
          {
            key: "actions",
            label: "Actions",
          },
        ];
      } else if (this.housingSelected == "Hébergements d'urgence") {
        return [
          {
            key: "hotel_info",
            label: "Hotel, nom, adresse, phone",
          },
          {
            key: "user",
            label: "Référent",
            formatter: (val) => (val ? val.initials : ""),
          },
          {
            key: "start_date",
            label: "Du",
            formatter: (val) =>
              val ? this.$moment(val).format("DD/MM/YYYY") : "",
          },
          {
            key: "end_date",
            label: "Au",
            formatter: (val) =>
              val ? this.$moment(val).format("DD/MM/YYYY") : "",
          },
          {
            key: "without_contract",
            label: "Dont hors contrat",
            formatter: (val) =>
              val && val[0] >= 0 ? val[0] + " nuits " + val[1] + " €" : "",
          },
          {
            key: "free_end_date",
            label: "Date de sortie",
            formatter: (val) =>
              val ? this.$moment(val).format("DD/MM/YYYY") : "",
          },
          {
            key: "nights",
            label: "Nuitées",
          },
          {
            key: "price",
            label: "Prix journée",
            formatter: (val) => (val ? val + " €" : ""),
          },
          {
            key: "total_price",
            label: "Total",
            formatter: (val) => (val ? val + " €" : ""),
          },
          {
            key: "total_payment",
            label: "Payé",
            formatter: (val) => val + " €",
          },
          {
            key: "solde",
            label: "Solde",
            formatter: (val) => val + " €",
          },
          {
            key: "actions",
            label: "Actions",
          },
        ];
      } else {
        return [
          {
            key: "nature",
            label: "Nature",
          },
          {
            key: "contact.organism",
            label: "Organisme",
            formatter: (val) => (val ? val.name : ""),
          },
          {
            key: "contact.contact",
            label: "Contact",
            formatter: (val) =>
              val ? val.surname + " " + val.name.toUpperCase() : "",
          },
          {
            key: "start_date",
            label: "Du",
            formatter: (val) =>
              val ? this.$moment(val).format("DD/MM/YYYY") : "",
          },
          {
            key: "end_date",
            label: "Au",
            formatter: (val) =>
              val ? this.$moment(val).format("DD/MM/YYYY") : "",
          },
          {
            key: "address_street",
            label: "Adresse",
          },
          {
            key: "address_postal_code",
            label: "CP",
          },
          {
            key: "address_city",
            label: "Ville",
          },
          {
            key: "tel",
            label: "Téléphone",
            formatter: telFormatter,
          },
          {
            key: "actions",
            label: "Actions",
          },
        ];
      }
    },
    getTitle() {
      return "Liste des hébergements";
    },
  },
  methods: {
    isTotal(item) {
      if (this.housingSelected == "Hébergements MRS") {
        console.log(item);
        return item.hotel.name == "TOTAUX";
      }
      return false;
    },
    housingClass(housing, type) {
      if (type == "row" && !this.isTotal(housing)) {
        if (
          this.$moment(housing.end_date) - this.$moment() < 0 &&
          (housing.free_end_date == "" || housing.free_end_date == undefined)
        ) {
          return "table-warning";
        } else if (
          this.$moment(housing.end_date) - this.$moment() > 0 &&
          this.$moment(housing.start_date) - this.$moment() <= 0
        ) {
          return "table-success";
        }
      } else {
        return;
      }
    },
    openCommentModal(payload) {
      this.$emit("openCommentModal", payload);
    },
    openHousingHelpModal(payload) {
      this.$emit("openHousingHelpModal", payload);
    },
    addTotal(housings) {
      if (this.housingSelected == "Hébergements MRS") {
        housings.push({
          hotel: {
            name: "TOTAUX",
            address: {},
          },
          without_contract: housings.reduce(
            (a, b) => [
              a[0] + b.without_contract[0],
              a[1] + b.without_contract[1],
            ],
            [0, 0]
          ),
          nights: housings.reduce((a, b) => a + b.nights, 0),
          total_price: housings.reduce((a, b) => a + b.total_price, 0),
          total_payment: housings.reduce((a, b) => a + b.total_payment, 0),
          solde: housings.reduce((a, b) => a + b.solde, 0),
          _rowVariant: "info",
        });
      } else if (this.housingSelected == "Hébergements d'urgence") {
        housings.push({
          hotel_info: "TOTAUX",
          without_contract: housings.reduce(
            (a, b) => [
              a[0] + b.without_contract[0],
              a[1] + b.without_contract[1],
            ],
            [0, 0]
          ),
          nights: housings.reduce((a, b) => a + b.nights, 0),
          total_price: housings.reduce((a, b) => a + b.total_price, 0),
          total_payment: housings.reduce((a, b) => a + b.total_payment, 0),
          solde: housings.reduce((a, b) => a + b.solde, 0),
          _rowVariant: "info",
        });
      }
    },
    getOutContract(housing) {
      let nights = housing.nights_out_contracts;
      return [nights, nights * housing.price];
    },
    refreshHousing() {
      if (this.housingSelected != "Hébergements hors MRS") {
        axios
          .get(`/api/web/recipient/${this.recipient_id}/housings`, {
            params: { housing_type: this.housingSelected },
          })
          .then((res) => {
            this.housings = res.data.data.map((h) => {
              h.without_contract = this.getOutContract(h);
              h.total_price = h.price * h.nights;
              if (h.payments !== undefined) {
                h.total_payment = h.payments.reduce((a, b) => a + b.amount, 0);
              } else {
                h.total_payment = 0;
              }
              h.solde = h.total_payment - h.total_price;
              return h;
            });
            this.addTotal(this.housings);
          });
      } else {
        axios
          .get(`/api/web/recipient/${this.recipient_id}/housings`, {
            params: { housing_type: this.housingSelected },
          })
          .then((res) => {
            this.housings = res.data.data;
          });
      }
    },
    addHousing() {
      this.$emit("openHousingModal", {
        recipient_id: this.recipient_id,
        housing: {},
        housingType: this.housingSelected,
      });
    },
    editHousing(housing) {
      this.$emit("openHousingModal", {
        recipient_id: this.recipient_id,
        housing: housing,
        housingType: this.housingSelected,
      });
    },
    askDelete(housing) {
      this.$toasted
        .show("Souhaitez-vous supprimer cet hébergement ?", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.delete(housing._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    delete(housing_id) {
      axios
        .delete(`/api/web/housing/${housing_id}`)
        .then(() => {
          this.$toasted.show("L'hébergement a été supprimé").goAway(1500);
          this.refreshHousing();
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss">
.table-info {
  font-weight: bold;
  td {
    font-weight: bold;
  }
}
</style>