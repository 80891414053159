<template>
  <div class="recipient-work">
    <div class="d-flex justify-content-center">
      <div class="d-flex align-items-center">
        <button
          class="btn m-2"
          :class="formation ? 'btn-primary' : 'btn-secondary'"
          type="button"
          @click="goWork"
        >
          Emploi
        </button>
        <button
          class="btn m-2"
          :class="formation ? 'btn-secondary' : 'btn-primary'"
          type="button"
          @click="goFormation"
        >
          Formation
        </button>
      </div>
    </div>
    <recipient-formation
      v-if="formation"
      :recipient_id="recipient_id"
      @openFormationModal="openFormationModal"
      @openQualificationModal="openQualificationModal"
    />
    <recipient-job
      v-else
      :recipient_id="recipient_id"
      @openJobModal="openJobModal"
      @openExperienceModal="openExperienceModal"
    />
    <comments
      :recipient_id="recipient_id"
      field="work"
      @openCommentModal="openCommentModal"
    />
  </div>
</template>

<script>
import RecipientFormation from "../work/RecipientFormation.vue";
import RecipientJob from "../work/RecipientJob.vue";
import Comments from "../comment/Comments.vue";
export default {
  name: "recipient-work",
  components: { RecipientFormation, Comments, RecipientJob },
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      formation: true,
    };
  },
  methods: {
    goWork() {
      this.formation = false;
    },
    goFormation() {
      this.formation = true;
    },
    openCommentModal(payload) {
      this.$emit("openCommentModal", payload);
    },
    openFormationModal(payload) {
      this.$emit("openFormationModal", payload);
    },
    openJobModal(payload) {
      this.$emit("openJobModal", payload);
    },
    openQualificationModal(payload) {
      this.$emit("openQualificationModal", payload);
    },
    openExperienceModal(payload) {
      this.$emit("openExperienceModal", payload);
    },
  },
};
</script>

<style>
</style>