<template>
  <div class="recipients">
    <div class="filter-list pt-4 pl-4 pr-4">
      <form
        novalidate
        class="md-layout"
        @submit.prevent="
          () => {
            refreshRecipientList();
          }
        "
      >
        <widget headerText="Filtrer les accueillis">
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="filter-name">Nom de famille</label>
                <md-input
                  name="filter-name"
                  id="filter-name"
                  v-model="filter.name"
                />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="filter-surname">Prénom</label>
                <md-input
                  name="filter-surname"
                  id="filter-surname"
                  v-model="filter.surname"
                />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <object-auto-complete
                v-model="filter.user"
                :options="users"
                field_of_value="_id"
                label="Accueillant référent"
              >
              </object-auto-complete>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="filter-file">Numéro de dossier</label>
                <md-input
                  name="filter-file"
                  id="filter-file"
                  v-model="filter.file_number"
                />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="recipients-dom">Domicilié</label>
                <md-select
                  v-model="filter.domiciliation"
                  name="recipients-dom"
                  id="recipients-dom"
                >
                  <md-option
                    v-for="(o, i) in ['Tous', 'Oui', 'Non']"
                    :key="i"
                    :value="o"
                    >{{ o }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="recipients-house">Hébergé</label>
                <md-select
                  v-model="filter.housed"
                  name="recipients-house"
                  id="recipients-house"
                >
                  <md-option
                    v-for="(o, i) in ['Tous', 'Oui', 'Non']"
                    :key="i"
                    :value="o"
                    >{{ o }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item d-flex align-items-center">
              <md-field class="no-underline">
                <div class="d-flex align-items-center justify-content-center">
                  Date de naissance supérieure à :
                  <vuestic-date-picker
                    v-model="filter.birth_date"
                    :config="dateConfig"
                  />
                </div>
              </md-field>
            </div>
          </div>
          <button v-if="!loading" type="submit" class="btn btn-primary">
            Filtrer
          </button>
        </widget>
      </form>
    </div>
    <div class="recipient-list pt-2 pl-4 pr-4">
      <widget headerText="Liste des Accueillis">
        <rotate-square2 v-if="loading"></rotate-square2>
        <b-table
          striped
          hover
          v-else
          :items="formattedRecipients"
          :fields="fields"
          :sort-by="filter.sortBy"
          :sort-desc="filter.sortDesc"
          @row-clicked="handleRowClick"
          @sort-changed="sortChanged"
        ></b-table>
        <div class="pagination d-flex justify-content-end">
          <nav aria-label="...">
            <ul class="pagination">
              <li class="page-item" v-if="page > 0">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page -= 1;
                    }
                  "
                  >Précédent</span
                >
              </li>
              <li class="page-item" v-if="page > 0">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page -= 1;
                    }
                  "
                  >{{ page }}</span
                >
              </li>
              <li class="page-item active">
                <span class="page-link" href="#"
                  >{{ page + 1 }} <span class="sr-only">(current)</span></span
                >
              </li>
              <li class="page-item" v-if="hasMorePages">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page += 1;
                    }
                  "
                  >{{ page + 2 }}</span
                >
              </li>
              <li class="page-item" v-if="hasMorePages">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page += 1;
                    }
                  "
                  >Suivant</span
                >
              </li>
            </ul>
          </nav>
        </div>
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget";
import axios from "axios";
import { mapState } from "vuex";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import ObjectAutoComplete from "../../components/widgets/ObjectAutoComplete.vue";
import { French } from "flatpickr/dist/l10n/fr.js";
import { RotateSquare2 } from "vue-loading-spinner";
export default {
  name: "recipients",
  components: { Widget, VuesticDatePicker, RotateSquare2, ObjectAutoComplete },
  data() {
    return {
      loading: true,
      users: [],
      recipients: [],
      page: 0,
      pages: 1,
      per_page: 20,
      filter: {
        domiciliation: "Tous",
        housed: "Tous",
        birth_date: null,
        file_number: null,
        sortBy: "file_id",
        sortDesc: true,
      },
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
    };
  },
  props: {
    searchInput: {
      type: String,
    },
  },
  watch: {
    page() {
      this.refreshRecipientList();
    },
    searchInput() {
      this.refreshRecipientList();
    },
  },
  computed: {
    ...mapState(["user", "antennas"]),
    hasMorePages() {
      return this.page + 1 < this.pages;
    },
    formattedRecipients() {
      return this.recipients.map(this.formatRecipient);
    },
    fields() {
      return [
        {
          key: "file_id",
          label: "N° dossier",
          sortable: true,
        },
        {
          key: "surname",
          label: "Prénom",
          sortable: true,
        },
        {
          key: "name",
          label: "Nom",
          sortable: true,
          formatter: (val) => val.toUpperCase(),
        },
        {
          key: "birth_date",
          label: "Date de naissance",
          sortable: true,
          formatter: this.dateFormatter,
        },
        {
          key: "antenna_id",
          label: "Antenne",
          sortable: true,
          formatter: this.formatAntenna,
        },
        {
          key: "last_meeting",
          label: "Dernier entretien",
          sortable: true,
          formatter: (val) => (val ? this.dateFormatter(val) : ""),
        },
        {
          key: "first_meeting",
          label: "Premier entretien",
          sortable: true,
          formatter: (val) => (val ? this.dateFormatter(val) : ""),
        },
        {
          key: "dom",
          label: "Domicilié",
          formatter: (val) => (val ? this.dateFormatter(val) : "Non"),
          tdClass: (value) => (value ? "table-info" : ""),
        },
        {
          key: "housing",
          label: "Hébergé",
          formatter: (val) => (val ? this.dateFormatter(val) : "Non"),
          tdClass: (value) => (value ? "table-info" : ""),
        },
        {
          key: "help_total",
          label: "Aides",
          sortable: true,
          formatter: (value) => {
            return value.toString() + " €";
          },
          tdClass: (value) => (value >= 250 ? "table-info" : ""),
        },
        {
          key: "radiated_on",
          label: "R",
          sortable: true,
          formatter: (val) => (val ? this.dateFormatter(val) : ""),
          tdClass: (value) => (value ? "table-danger" : ""),
        },
        {
          key: "mail_redirection",
          label: "T",
          sortable: true,
          formatter: (value) => {
            return value ? (value.replaceAll(" ", "") ? "Oui" : "") : "";
          },
          tdClass: (value) =>
            value ? (value.replaceAll(" ", "") ? "table-info" : "") : "",
        },
      ];
    },
  },
  mounted() {
    this.refreshRecipientList();
    this.refreshUsers();
  },
  methods: {
    sortChanged(e) {
      this.filter.sortBy = e.sortBy;
      this.filter.sortDesc = e.sortDesc;
      if (this.filter.sortBy == "file_id") {
        this.filter.sortBy = "file_number";
      }
      this.refreshRecipientList();
    },
    formatRecipient(recipient) {
      return recipient;
    },
    refreshUsers() {
      axios
        .get("/api/web/users")
        .then((res) => {
          this.users = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    formatFilter(filter) {
      let resultFilter = {};
      if (filter.domiciliation != "Tous") {
        resultFilter.domiciliation = filter.domiciliation == "Oui";
      }
      if (filter.housed != "Tous") {
        resultFilter.housed = filter.housed == "Oui";
      }
      if (filter.birth_date) {
        resultFilter.birth_date = filter.birth_date;
      }
      if (filter.file_number) {
        if (filter.file_number.indexOf(".") >= 0) {
          resultFilter.file_number = filter.file_number.slice(
            filter.file_number.indexOf(".") + 1
          );
        }
      }
      if (filter.name) {
        resultFilter.name = filter.name;
      }
      if (filter.surname) {
        resultFilter.surname = filter.surname;
      }
      if (filter.user) {
        resultFilter.user = filter.user;
      }
      if (filter.sortBy == "file_id") {
        resultFilter.sortBy = "file_number";
      } else {
        resultFilter.sortBy = filter.sortBy;
      }
      resultFilter.sortDesc = filter.sortDesc;
      return resultFilter;
    },
    refreshRecipientList() {
      let filter = this.formatFilter(this.filter);
      this.loading = true;
      axios
        .get("/api/web/recipients", {
          params: {
            page: this.page,
            per_page: this.per_page,
            filter: filter,
          },
        })
        .then((response) => {
          this.recipients = response.data.data;
          this.pages = response.data.pagination.last_page + 1;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    dateFormatter(date) {
      if (date) {
        return this.$moment(date).format("DD/MM/YYYY");
      }
    },
    formatAntenna(antenna_id) {
      if (antenna_id) {
        let hisAntenna = this.antennas.filter(
          (antenna) => antenna._id == antenna_id
        );
        if (hisAntenna.length > 0) {
          return hisAntenna[0].name;
        }
      }
      return "";
    },
    handleRowClick(row) {
      this.$router.push(`/recipient/${row._id}#id`);
    },
  },
};
</script>

<style lang="scss">
.page-link {
  cursor: pointer;
}
</style>