<template>
  <div class="recipient">
    <tabs :tabs="tabs" @clickTab="selectTab"> </tabs>
    <vuestic-alert type="info">
      <span class="badge badge-pill badge-info">{{ getTitle }}</span>
      <span v-if="getJustice" class="badge badge-pill" :class="justiceClass">{{
        getJustice
      }}</span>
    </vuestic-alert>
    <recipient-civil
      v-if="selectedTabId == 'id'"
      :recipient_id="recipient_id"
      @openCommentModal="openCommentModal"
    />
    <recipient-detention
      v-if="selectedTabId == 'sit-pen'"
      :recipient_id="recipient_id"
      @openCommentModal="openCommentModal"
      @openDetentionModal="openDetentionModal"
      @openFollowModal="openFollowModal"
      @justiceChange="refreshRecipient"
    />
    <recipient-domiciliation
      v-if="selectedTabId == 'dom'"
      :recipient_id="recipient_id"
      @openCommentModal="openCommentModal"
      @openDomModal="openDomModal"
      @openMailModal="openMailModal"
    />
    <recipient-contacts
      v-if="selectedTabId == 'contact'"
      :recipient_id="recipient_id"
      @openCommentModal="openCommentModal"
      @openRecipientContactModal="openRecipientContactModal"
    />
    <recipient-health
      v-if="selectedTabId == 'health'"
      :recipient_id="recipient_id"
      @openCommentModal="openCommentModal"
    />
    <recipient-housing
      v-if="selectedTabId == 'housing'"
      :recipient_id="recipient_id"
      @openCommentModal="openCommentModal"
      @openHousingModal="openHousingModal"
      @openHousingHelpModal="openHousingHelpModal"
    />
    <recipient-meetings
      v-if="selectedTabId == 'meetings'"
      :recipient_id="recipient_id"
      @openCommentModal="openCommentModal"
      @openMeetingModal="openMeetingModal"
    />
    <recipient-work
      v-if="selectedTabId == 'work'"
      :recipient_id="recipient_id"
      @openCommentModal="openCommentModal"
      @openFormationModal="openFormationModal"
      @openJobModal="openJobModal"
      @openQualificationModal="openQualificationModal"
      @openExperienceModal="openExperienceModal"
    />
    <recipient-resources
      v-if="selectedTabId == 'resources'"
      :recipient_id="recipient_id"
      @openCommentModal="openCommentModal"
      @openResourceModal="openResourceModal"
    />
    <recipient-attachments
      v-if="selectedTabId == 'attachments'"
      :recipient_id="recipient_id"
      @openAttachmentModal="openAttachmentModal"
    />
  </div>
</template>

<script>
import RecipientCivil from "../../components/recipient/RecipientCivil.vue";
import RecipientDetention from "../../components/recipient/RecipientDetention.vue";
import RecipientDomiciliation from "../../components/recipient/RecipientDomiciliation.vue";
import VuesticAlert from "../../components/widgets/VuesticAlert.vue";
import Tabs from "../../components/tabs/Tabs.vue";
import { tabList } from "./tabList";
import axios from "axios";
import RecipientContacts from "../../components/recipient/RecipientContacts.vue";
import RecipientHealth from "../../components/recipient/RecipientHealth.vue";
import RecipientMeetings from "../../components/recipient/RecipientMeetings.vue";
import RecipientResources from "../../components/recipient/RecipientResources.vue";
import RecipientHousing from "../../components/recipient/RecipientHousing.vue";
import RecipientWork from "../../components/recipient/RecipientWork.vue";
import RecipientAttachments from "../../components/recipient/RecipientAttachments.vue";
export default {
  components: {
    Tabs,
    RecipientCivil,
    RecipientDetention,
    RecipientDomiciliation,
    VuesticAlert,
    RecipientContacts,
    RecipientHealth,
    RecipientMeetings,
    RecipientResources,
    RecipientHousing,
    RecipientWork,
    RecipientAttachments,
  },
  name: "recipient",
  data() {
    return {
      recipient_id: this.$route.params.recipient_id,
      tabs: tabList,
      selectedTabId: this.$route.hash.replace("#", ""),
      recipient: {},
      follow: null,
    };
  },
  mounted() {
    this.refreshRecipient();
    if (tabList.map((t) => t.id).indexOf(this.selectedTabId) < 0) {
      this.selectTab(tabList[0]);
    } else {
      this.tabs = this.tabs.map((t) => {
        t.click = t.id == this.selectedTabId;
        return t;
      });
    }
  },
  watch: {
    recipient_id() {
      this.refreshRecipient();
    },
  },
  computed: {
    justiceClass() {
      if (this.follow != null) {
        return "badge-info";
      } else {
        return "badge-warning";
      }
    },
    getJustice() {
      if (this.follow != null) {
        let organism = "";
        let start = "???";
        let end = "???";
        if (this.follow.organism != null) {
          organism = " (" + this.follow.organism.name + ")";
        }
        if (this.follow.start_date != null) {
          start = this.$moment(this.follow.start_date).format("DD/MM/YYYY");
        }
        if (this.follow.end_date != null) {
          end = this.$moment(this.follow.end_date).format("DD/MM/YYYY");
        }

        return "Sous main de justice du : " + start + " au " + end + organism;
      }
      return "Pas de suivi en cours";
    },
    getTitle() {
      if (this.recipient.name) {
        if (this.recipient.surname2) {
          return (
            "Dossier n°" +
            this.recipient.file_id +
            " " +
            this.recipient.surname +
            " " +
            this.recipient.surname2 +
            " " +
            this.recipient.name.toUpperCase()
          );
        }
        return (
          "Dossier n°" +
          this.recipient.file_id +
          " " +
          this.recipient.surname +
          " " +
          this.recipient.name.toUpperCase()
        );
      }
      return "";
    },
  },
  methods: {
    refreshRecipient() {
      axios.get(`/api/web/recipient/${this.recipient_id}`).then((res) => {
        this.recipient = res.data.data;
      });
      axios
        .get(`/api/web/recipient/${this.recipient_id}/follow/${this.$moment()}`)
        .then((res) => {
          this.follow = res.data.data;
        });
    },
    selectTab(tab) {
      this.tabs = this.tabs.map((t) => {
        t.click = t.id == tab.id;
        return t;
      });
      this.selectedTabId = tab.id;
      this.$router.push(`/recipient/${this.recipient_id}#${tab.id}`);
    },
    openCommentModal(payload) {
      this.$emit("openCommentModal", payload);
    },
    openDetentionModal(payload) {
      this.$emit("openDetentionModal", payload);
    },
    openFollowModal(payload) {
      this.$emit("openFollowModal", payload);
    },
    openDomModal(payload) {
      this.$emit("openDomModal", payload);
    },
    openRecipientContactModal(payload) {
      this.$emit("openRecipientContactModal", payload);
    },
    openMeetingModal(payload) {
      this.$emit("openMeetingModal", payload);
    },
    openHousingModal(payload) {
      this.$emit("openHousingModal", payload);
    },
    openHousingHelpModal(payload) {
      this.$emit("openHousingHelpModal", payload);
    },
    openFormationModal(payload) {
      this.$emit("openFormationModal", payload);
    },
    openJobModal(payload) {
      this.$emit("openJobModal", payload);
    },
    openResourceModal(payload) {
      this.$emit("openResourceModal", payload);
    },
    openQualificationModal(payload) {
      this.$emit("openQualificationModal", payload);
    },
    openExperienceModal(payload) {
      this.$emit("openExperienceModal", payload);
    },
    openAttachmentModal(payload) {
      this.$emit("openAttachmentModal", payload);
    },
    openMailModal(payload) {
      this.$emit("openMailModal", payload);
    },
  },
};
</script>

<style lang="scss">
</style>