<template>
  <div class="home">
    <div class="d-flex">
      <antenna-fiche />
      <siege-fiche />
    </div>
    <div class="d-flex">
      <alert-dashboard />
      <data-quality-dashboard />
    </div>
    <div class="d-flex">
      <housing-dashboard />
      <data-quality-dashboard />
    </div>
    <div class="d-flex">
      <activity-dashboard :user="user" />
    </div>
    <div class="d-flex">
      <activity-dashboard :antenna="user.antenna" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AntennaFiche from "../components/antenna/AntennaFiche.vue";
import SiegeFiche from "../components/antenna/SiegeFiche.vue";
import ActivityDashboard from "../components/dashboard/ActivityDashboard.vue";
import AlertDashboard from "../components/dashboard/AlertDashboard.vue";
import DataQualityDashboard from "../components/dashboard/DataQualityDashboard.vue";
import HousingDashboard from "../components/dashboard/HousingDashboard.vue";
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    AntennaFiche,
    SiegeFiche,
    AlertDashboard,
    DataQualityDashboard,
    ActivityDashboard,
    HousingDashboard,
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style lang="scss" scoped>
.antenna-fiche {
  flex: 2;
}
.siege-fiche {
  flex: 1;
}
.alert-dashboard {
  flex: 1;
}
.activity-dashboard {
  flex: 1;
}
.data-quality-dashboard {
  flex: 1;
}
.housing-dashboard {
  flex: 1;
}
</style>