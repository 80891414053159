<template>
  <div class="navbar" :class="{ reduced: isReduced }">
    <div
      class="navbar-elements d-flex justify-content-between align-items-center"
    >
      <div />
      <search-box @search="search" />
      <div class="user-profile pl-4 d-flex align-items-center">
        <div class="alerts p-2" v-on:click="goToAlerts">
          <ion-icon name="notifications" />
          <div class="alerts-count unread-alerts">{{ alertCounts.unRead }}</div>
          <div class="alerts-count unhandled-alerts">
            {{ alertCounts.unHandled }}
          </div>
        </div>
        <div class="profile d-flex align-items-center" v-on:click="goToProfile">
          <ion-icon name="person-circle" />
          <div>
            <div>Utilisateur connecté</div>
            <div>
              {{
                user.surname + " " + (user.name ? user.name.toUpperCase() : "")
              }}
            </div>
          </div>
        </div>
        <div>
          <div
            v-on:click="logout"
            v-b-tooltip.on
            class="p-2 logout"
            title="Déconnexion"
          >
            <ion-icon name="power" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SearchBox from "./SearchBox.vue";
import axios from "axios";
export default {
  name: "navbar",
  components: {
    SearchBox,
  },
  props: {
    isReduced: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.refreshAlertCounts();
  },
  data() {
    return {
      alertCounts: { unRead: 0, unHandled: 0 },
    };
  },
  computed: {
    ...mapState(["user", "refreshFlag"]),
  },
  watch: {
    refreshFlag() {
      if (this.refreshFlag == "alerts") {
        this.refreshAlertCounts();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  methods: {
    refreshAlertCounts() {
      axios.get("/api/web/alerts/count").then((res) => {
        this.alertCounts = res.data.data;
      });
    },
    logout() {
      axios.post("/api/auth/logout").then(() => {
        this.$router.push("/auth/login");
      });
    },
    goToProfile() {
      this.$router.push(`/user/${this.user._id}`);
    },
    goToAlerts() {
      this.$router.push(`/alerts`);
    },
    search(payload) {
      this.$emit("search", payload);
    },
  },
};
</script>

<style lang="scss">
.navbar {
  height: 75px;
  margin-left: 200px;
  background-color: $ulysse-blue-dark;

  &.reduced {
    margin-left: 50px;
  }

  .navbar-elements {
    color: aliceblue;
    width: 100%;
    .user-profile {
      .alerts {
        ion-icon {
          font-size: 2rem;
        }
        cursor: pointer;
        position: relative;
        .alerts-count {
          border-radius: 0.4rem;
          padding-left: 0.2rem;
          padding-right: 0.2rem;
          color: black;
          text-align: center;
          text-anchor: center;
          z-index: 2;
        }
        .unread-alerts {
          position: absolute;
          left: 0rem;
          top: 0.2rem;
          background-color: #f49e4c;
        }
        .unhandled-alerts {
          position: absolute;
          right: 0rem;
          top: 0.2rem;
          background-color: #f5ee9e;
        }
      }
      .profile {
        ion-icon {
          font-size: 3rem;
        }
        cursor: pointer;
      }

      .logout {
        ion-icon {
          font-size: 2rem;
        }
        cursor: pointer;
      }
    }
  }
}
</style>